import { useContext } from "react"
import { Context } from "../../store/store"
import ElemCheckbox from "../elements/Checkbox"
import ElemSelect from "../elements/Select"
import { Link } from "react-router-dom"
import { getEnvConfig } from "../../env"

interface LegalProps {
	formElementChanged: Function
	validation: Object
}
const Legal: React.FC<LegalProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<div className=" max-w-[1100px] mt-12 mx-auto text-black">
			<ElemCheckbox
				label={
					<>
						Ich akzeptiere die{" "}
						<Link to="/teilnahmebedingungen" target="_blank" className="underline">
							Teilnahmebedingungen
						</Link>
						.*
					</>
				}
				props={{
					type: "checkbox",
					name: "privacyTermsOptIn",
					required: true,
					value: globalState.privacyTermsOptIn,
					onClick: e => formElementChanged(e, "privacyTermsOptIn"),
				}}
				hasError={validation["invalid_privacyTermsOptIn"] ? true : false}
				className="sm:pb-10"
				errMsg={validation["invalid_privacyTermsOptIn_errMsg"] || "Bitte Teilnahmebedingungen akzeptieren."}
				errorMsgPadding="mt-10 lg:mt-2 pl-10 lg:pl-12"
			/>
			<ElemCheckbox
				label={
					<>
						Ich bin einverstanden, dass Dr. Oetker meine E-Mail Adresse in verschlüsselter Form mit Marketing-Partnern teilt, damit Dr. Oetker und seine Partner auf ihren
						Plattformen diese Daten für personalisierte Angebote nutzen können. Genaue Informationen findest Du in unserem{" "}
						<Link to="/datenschutz" target="_blank" className="underline">
							Datenschutzhinweis
						</Link>
						, hier sind auch unsere Marketing-Partner benannt. Du kannst dich jederzeit umentscheiden.
					</>
				}
				props={{
					type: "checkbox",
					name: "mafoOptIn",
					value: globalState.mafoOptIn,
					onClick: e => formElementChanged(e, "mafoOptIn"),
				}}
				hasError={validation["invalid_mafoOptIn"] ? true : false}
				className="sm:pb-10 mt-12 lg:mt-0"
				errMsg={validation["invalid_mafoOptIn_errMsg"] || "Bitte dein Alter bestätigen."}
				errorMsgPadding="mt-10 2sm:mt-4 lg:mt-0 pl-10 lg:pl-12"
			/>
		</div>
	)
}

export default Legal
