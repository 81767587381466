import React, { useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import AccordionItem from "../components/elements/AccordionItem"
import Hero from "../components/home/Hero"
import Header from "../components/Header"

const Privacy: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-white">
			<Hero page="terms" />
			<>
				<div className="container max-w-[1600px] mx-auto px-4 ">
					<div>
						<h2 className="h2 mb-4 lg:mb-16 text-center mt-4 lg:mt-16 lg:leading-[52px] text-[36px] leading-[42px]">
							<div className="text-darkgreen  lg:mb-16 font-Atma font-bold ">Datenschutzhinweis – Dr. Oetker Intermezzo </div>
						</h2>
					</div>
					<div className="max-w-[800px] mx-auto">
						<div className="text-darkgreen p-6">
							<p className="mb-6">
								Diese Datenschutzerklärung informiert über die Verarbeitung Ihrer personenbezogenen Daten auf unserer Webseite{" "}
								<a href="https://www.intermezzo-cashback.de" className="underline" target="_blank">
									www.intermezzo-cashback.de
								</a>
								. Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst und erheben personenbezogene Daten daher grundsätzlich nur im notwendigen Umfang. Die nachfolgende
								Information gibt Ihnen einen Überblick darüber, welche Ihrer personenbezogenen Daten zu welchen Zwecken bei Nutzung unserer Websites oder anderen Anlässen
								verarbeitet werden. Wir halten diese Datenschutzinformation immer auf dem aktuellen Stand und passen sie erforderlichenfalls an veränderte tatsächliche oder
								gesetzliche Rahmenbedingungen an. Diese Anpassungen werden mit Nutzung unserer Websites und der Teilnahme an der Aktion akzeptiert.
							</p>
							<p className="font-bold text-[32px] mb-6 mt-12 leading-[42px]">1. Verantwortlicher</p>
							<p className="mb-6">
								Verantwortlicher für die in dieser Datenschutzerklärung beschriebenen Verarbeitungen Ihrer personenbezogenen Daten ist folgendes Unternehmen: Dr. August Oetker
								Nahrungsmittel KG, Lutterstraße 14, 33617 Bielefeld, Telefon: +49 (0) 521 155-0 E-Mail:{" "}
								<a href="mailto:dsgvo-service@oetker.com" className="underline">
									dsgvo-service@oetker.com
								</a>
							</p>
							<p className="font-bold text-[32px] mb-6 mt-12 leading-[42px]">2. Datenschutzbeauftragter</p>
							<p className="mb-6">
								Bei Fragen zur Verarbeitung Ihrer personenbezogenen Daten wenden Sie sich bitte an unseren Datenschutzbeauftragten: Dr. August Oetker, Nahrungsmittel KG
								Datenschutzbeauftragter, Lutterstraße 14, 33617 Bielefeld, E-Mail:{" "}
								<a className="underline" href="mailto:oetker-nm@corporate-data-protection.com">
									oetker-nm@corporate-data-protection.com
								</a>
							</p>
							<p className="font-bold text-[32px] mb-6 mt-12 leading-[42px]">3. Details zu einzelnen Verarbeitungen</p>
							<p className="font-bold">a. Durchführung der Aktion</p>
							<p className="mb-6">
								Wir erheben im Rahmen der Cashback-Aktion personenbezogene Daten, die zu ihrer Durchführung erforderlich sind. Diese sind im Anmeldeformular als Pflichtfelder
								gekennzeichnet. Rechtsgrundlage für die Verarbeitung dieser Daten ist Art. 6 Abs. 1 b) DSGVO. Falls Sie diese Daten nicht bereitstellen, können Sie an der Aktion
								nicht teilnehmen.
							</p>
							<p className="mb-6">
								Die darüber hinaus erhobenen Daten sind freiwillig und erfolgen auf der rechtlichen Grundlage unseres berechtigten Interesses (Art. 6 Abs. 1 f) DSGVO). Die Anrede
								fragen wir ab, um Sie wunschgemäß ansprechen zu können. Die Beantwortung der Fragen zum gekauften Produkt und zur Cashback-Aktion ist für uns wichtig, weil wir
								daraus Erkenntnisse und Entscheidungen zur Produktentwicklung und -pflege ableiten.
							</p>
							<p className="mb-6">
								Wir speichern die personenbezogenen Daten der Teilnehmer bis zu 6 Monate nach Beendigung der Aktion, sofern keine längerfristigen gesetzlichen
								Aufbewahrungspflichten bestehen
							</p>
							<p className="mb-6">
								Im Rahmen mancher Promotion-Aktionen verknüpfen wir die Teilnahme an der Aktion mit der Verpflichtung zur Zustimmung für weitere Marketingmaßnahmen (zum Beispiel
								Newsletterbezug) und nutzen - mit Ihrer Einwilligung - die im Rahmen der Promotion-Aktion erhobenen Daten ggf. auch für Personalisierung von Marketingmaßnahmen. In
								diesem Fall ist Ihre gesondert erteilte Einwilligung Rechtsgrundlage für die Verarbeitung dieser Daten (Art. 6 Abs. 1 a) DSGVO).
							</p>
							<p className="mb-6">
								Zur Durchführung der Aktion setzen wir als Dienstleister die direct services Gütersloh GmbH (Reinhard-Mohn-Straße 300, 33333 Gütersloh Germany) ein, mit der ein
								Auftragsverarbeitungsverhältnis besteht. Die Auszahlung der Cashback-Beträge erfolgt durch die Riverty GmbH (Gütersloher Str. 123, 33415 Verl)
							</p>
							<p className="font-bold">b. Kontaktaufnahme / Feedback</p>
							<p className="mb-6">
								Sie können via E-Mail Kontakt oder telefonisch mit uns aufnehmen. Wir verarbeiten Ihre Daten zur Beantwortung Ihrer Anfrage. Darin liegt auch das berechtigte
								Interesse für die Datenverarbeitung. Rechtliche Grundlage hierfür ist unser berechtigtes Interesse (Art. 6 Abs. 1 f) DSGVO).
							</p>
							<p className="font-bold">c. Server-Protokollierung</p>
							<p className="mb-6">
								Bei jedem Aufruf unserer Webseite protokollieren wir in Server-Logdateien Daten, die von Ihrem Endgerät stammen und auch personenbezogene Daten umfassen können.
								Dies gilt für folgende Daten:
							</p>
							<ul className="list-inside list-disc mb-6">
								<li className="py-2">Das Betriebssystem Ihres Endgeräts </li>
								<li className="py-2">Der von Ihnen verwendete Browsertyp (bei Aufruf der Webseite) </li>
								<li className="py-2">Name Ihres Providers </li>
								<li className="py-2">Ihre IP-Adresse </li>
								<li className="py-2">Datum und Uhrzeit des Aufrufs </li>
								<li className="py-2">Die besuchten Seiten unseres Angebots inkl. etwaiger Suchwörter </li>
								<li className="py-2">
									Die Webseiten, von denen aus Sie auf unsere Webseite gelangt sind (bei Aufruf der Webseite)Die Verarbeitung ist erforderlich, um die Funktionsfähigkeit der
									Webseite sicherzustellen sowie die Inhalte unserer Webseite zu optimieren und korrekt auszuliefern und um Strafverfolgungsbehörden im Falle eines Cyberangriffes
									die zur Strafverfolgung notwendigen Informationen bereitzustellen. Darin liegt auch das berechtigte Interesse für die Datenverarbeitung. Rechtliche Grundlage
									hierfür ist unser berechtigtes Interesse (Art. 6 Abs. 1 f) DSGVO).
								</li>
							</ul>
							<p className="mb-6">
								Die o.g. Daten werden gelöscht, sobald sie für die Erreichung der vorgenannten Zwecke nicht mehr erforderlich sind. Im Falle der Verarbeitung Ihrer IP-Adresse
								erfolgt die Löschung spätestens nach 3 Monaten, bei Missbrauchsfällen nach Abschluss des behördlichen oder gerichtlichen Verfahrens.
							</p>
							<p className="font-bold">d. Hinweis zu Datenverarbeitung in den USA </p>
							<p className="mb-6">
								Wir arbeiten zum Zweck der “Analyse und Personalisierung - Tracking” mit Technologieanbietern (als Auftragsverarbeiter) zusammen, bei denen ein Transfer Ihrer
								personenbezogenen Daten in die USA nicht ausgeschlossen ist.
							</p>
							<p className="mb-6">
								Sofern möglich, weisen wir unsere Technologieanbieter an, Ihre personenbezogenen Daten in Rechenzentren innerhalb der Europäischen Union zu verarbeiten.
							</p>
							<p className="mb-6">
								Bei Technologieanbietern oder Partnern, die ebenfalls über eine Niederlassung in den USA verfügen oder dort mit anderen Unternehmen ihrer Unternehmensgruppe
								ansässig sind, können wir jedoch nicht ausschließen, dass US-Sicherheitsbehörden Zugriff auf Ihre personenbezogenen Daten erhalten. Dies kann z.B. über einen
								Fernwartungszugang oder eine rechtlich verbindliche Anweisung an unseren Technologieanbieter oder Partner erfolgen. Es besteht für Sie hierdurch insbesondere das
								Risiko, dass US-Sicherheitsbehörden anlasslos und in großem Maßstab auf Ihre personenbezogenen Daten zugreifen und Sie hiergegen in den USA nicht wirksam rechtlich
								vorgehen können.
							</p>
							<p className="font-bold">e. Tracking und Cookies</p>
							<p className="mb-6">
								{" "}
								Unsere Webseite verwendet Cookies und ähnliche Technologien, die Informationen auf Ihrem Endgerät speichern bzw. auslesen, zu folgenden Zwecken:
							</p>
							<ul className="list-inside list-disc mb-6">
								<li className="py-2 font-bold">Ermöglichung der Funktionalität der Webseite </li>
								<li className="py-2 font-bold">Analyse und Personalisierung - Tracking </li>
							</ul>
							<p className="mb-6 font-bold">Ermöglichung der Funktionalität der Webseite </p>
							<p className="mb-6">
								Wir verwenden Cookies und ähnliche Technologien, um bestimmte Funktionen der Webseite zu ermöglichen. Dies ist unbedingt erforderlich, um unsere Webseite
								bereitzustellen. Wir können so z.B. Ihre Auswahl bzw. Einstellungen hinsichtlich einer Einwilligung für Tracking speichern und Tracking-Technologien entsprechend
								Ihrer Wahl aussteuern.
							</p>
							<p className="mb-6">
								Unser berechtigtes Interesse für die Datenverarbeitung liegt darin, die möglichst reibungslose Benutzbarkeit unserer Webseite / App zu ermöglichen bzw.
								sicherzustellen, dass Benutzeraktionen tatsächlich von einem authentifizierten Benutzer ausgehen. Rechtliche Grundlage hierfür ist unser berechtigtes Interesse
								(Art. 6 Abs. 1 f) DSGVO) bzw. § 25 Abs. 2 Nr. 2 TTDSG.
							</p>
							<p className="mb-6 font-bold">Analyse und Personalisierung – Tracking</p>
							<p className="mb-6">
								Wir verwenden Cookies und ähnliche Technologien, um das Nutzerverhalten auf unserer Webseite auszuwerten und diese im Hinblick darauf anzupassen.
							</p>
							<p className="mb-6">
								Sofern Sie darin eingewilligt haben, verarbeiten wir bei jeder Interaktion mit unserer Webseite Angaben zu Ihrem verwendeten Endgerät und dem von Ihnen genutzten
								Browser. Wir können so zum Beispiel herausfinden, ob Sie ein Smartphone oder einen Desktop-Computer verwenden oder aus welchem Land Sie unsere Webseite aufrufen.
							</p>
							<p className="mb-6">
								Zudem verarbeiten wir Informationen darüber, wie Sie mit unserer Webseite interagieren, z.B. von woher Sie auf unsere Webseite verwiesen werden, wie lang Sie unsere
								Webseite nutzen und auf welchem Pfad Sie durch einzelne Unterseiten navigieren. Wir können so zum Beispiel erfassen, ob Sie uns über eine Suchmaschine (und wenn ja,
								unter welchem Suchbegriff) oder eine Werbeanzeige gefunden haben, wie lange Sie sich mit dem Inhalt einer bestimmten Seite befasst haben und wie viele Seiten Sie
								insgesamt besucht haben.
							</p>
							<p className="mb-6">
								Wir verwenden diese Informationen, um Analysen über das Nutzerverhalten auf unserer Webseite zu erstellen und die Webseite entsprechend zu optimieren.
							</p>
							<p className="mb-6">
								Rechtliche Grundlage hierfür ist Ihre Einwilligung (Art. 6 Abs. 1 a) DSGVO bzw. § 25 Abs. 1 TTDSG). Ihre Einwilligung können Sie jederzeit mit Wirkung für die
								Zukunft widerrufen oder anpassen. Dazu können Sie unser Menü zu Tracking-Einstellungen aufrufen. Sie können ebenfalls in dem von Ihnen verwendeten Browser
								Einstellungen zur Nutzung von Cookies und ähnlichen Technologien treffen.
							</p>
							<p className="mb-6">Wir verwenden für Analyse und Personalisierung folgende Produkte:</p>
							<p className="mb-6 font-bold">Google Analytics</p>
							<p className="mb-6">
								Google Analytics ist ein Dienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (“Google”). Google verarbeitet Ihre Daten in unserem
								Auftrag. Unsere Konfiguration von Google Analytics erlaubt es Google nicht, die über Google Analytics erfassten Daten zur{" "}
								<a target="_blank" href="https://support.google.com/analytics/answer/1011397#zippy=%2Cthemen-in-diesem-artikel%2Cin-this-article" className="underline">
									Verbesserung von Google-Produkten und -Services
								</a>{" "}
								zu nutzen.
							</p>
							<p className="mb-6">
								Information über die im Rahmen von Google Analytics verwendeten Cookies und deren Laufzeit finden Sie{" "}
								<a target="_blank" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" className="underline">
									hier
								</a>
								.
							</p>
							<p className="mb-6">
								Google ist mit anderen Unternehmen der gleichen Unternehmensgruppe (insbesondere mit der Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043. USA) in den
								USA niedergelassen und nutzt diese als Auftragsverarbeiter, so dass wir einen Transfer Ihrer personenbezogenen Daten in die USA nicht ausschließen können.
							</p>
							<p className="mb-6 font-bold">(Re-)Marketing - Datenaustausch mit Dritten</p>
							<p className="mb-6">
								Wir verwenden Cookies und ähnliche Technologien, um auszuwerten, welche Reichweiten und Interaktionen unsere Werbekampagnen erreichen und wie Benutzer, die über
								unsere Werbekampagnen auf unsere Webseite / App aufmerksam geworden sind, diese verwenden. Zudem erfassen wir über Cookies und ähnliche Technologien, welche
								Benutzer unsere Webseite / App benutzen, um hieraus Gruppen (sog. Audiences) zu bilden und diese Audiences auf anderen Online-Plattformen mit personalisierter
								Werbung zu adressieren.
							</p>
							<p className="mb-6">Diese Informationen geben wir auch an Dritte weiter, die sie gegebenenfalls auch zu eigenen Zwecken verarbeiten.</p>
							<p className="mb-6">
								Sofern Sie darin eingewilligt haben, erfassen wir, ob Sie uns über eine unserer Werbekampagnen erreicht haben und wie Sie dann mit unserer Webseite / App
								interagieren. Wir können so zum Beispiel erfassen, ob Sie unsere App installiert haben, nachdem Sie eine unserer Anzeigen im Internet gesehen haben. Außerdem können
								wir so erfassen, welcher Anteil der Nutzer, die eine Anzeige gesehen haben, tatsächlich Inhalte unserer Webseite / App nutzen. So können wir unser Werbebudget
								besser planen und steuern.
							</p>
							<p className="mb-6">
								Zudem gruppieren wir die Nutzer unserer Webseite / App in sogenannte Audiences. Audiences sind Nutzergruppen, die von uns nach bestimmten Kriterien zusammengestellt
								werden (z.B. alle Nutzer, die eine Kategorie von Rezepten aufgerufen haben). Sofern die Nutzer einer Audience auch Nutzer unserer Marketing-Partner sind, können wir
								diese Nutzer dort mit Anzeigen adressieren (Re-Marketing). Informationen, die unser Marketing-Partner über seine Nutzer in einer unser Audiences hat, kann er auch
								dazu nutzen, um uns ähnliche Nutzergruppen für Werbung vorzuschlagen.
							</p>
							<p className="mb-6">
								Wir teilen dazu unseren Marketing-Partnern mit, dass Sie unsere Webseite besucht haben bzw. unsere App nutzen und ob Sie von uns definierte Seiten besucht oder
								andere Aktionen getätigt haben (z.B. einen Account registriert). Unsere Marketing-Partner verwenden diese Informationen gegebenenfalls auch zu eigenen Zwecken, zum
								Beispiel um Ihr Profil auf ihrer Online-Plattform weiter zu personalisieren.{" "}
							</p>
							<p className="mb-6">
								Rechtliche Grundlage hierfür ist Ihre Einwilligung (Art. 6 Abs. 1 a) DSGVO bzw. § 25 Abs. 1 TTDSG). Ihre Einwilligung können Sie jederzeit mit Wirkung für die
								Zukunft widerrufen oder anpassen. Dazu können Sie unser Menü zu Tracking-Einstellungen aufrufen. Sie können ebenfalls in dem von Ihnen verwendeten Browser
								Einstellungen zur Nutzung von Cookies und ähnlichen Technologien treffen.
							</p>
							<p className="mb-6">
								Für die Datenverarbeitungen, die unsere Marketing-Partner zu eigenen Zwecken vornehmen, sind diese selbst datenschutzrechtlich verantwortlich. Der Marketing-Partner
								stellt Ihnen hierzu ebenfalls Datenschutz-Informationen bereit.
							</p>
							<p className="">Wir verwenden für (Re-)Marketing und Datenaustausch mit Dritten folgende Produkte bzw. arbeiten mit folgenden Marketing-Partnern zusammen:</p>
							<p className="mb-6">
								Meta Pixel (Webseite, für Facebook / Instagram) MetaPixel ist ein Produkt der Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour Dublin 2,
								Irland (“Meta”). Mehr Informationen zu MetaPixel finden Sie hier. Bitte beachten Sie auch die{" "}
								<a target="_blank" href="https://www.facebook.com/about/privacy/update" className="underline">
									Facebook-Datenrichtlinie
								</a>
								.
							</p>
							<p className="font-bold text-[32px] mb-6 mt-12 leading-[42px]">4. Widerruf von Einwilligungen (Art. 7 Abs. 3 DSGVO)</p>
							<p className="mb-6">
								Sofern die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 a) DSGVO) beruht, haben Sie das Recht, Ihre Einwilligung jederzeit mit Wirkung für die Zukunft zu
								widerrufen. Hierzu können Sie sich an uns über die im Abschnitt “Verantwortlicher” angegebenen Kontaktmöglichkeiten wenden oder die im Kontext der jeweiligen
								Verarbeitung beschriebenen Möglichkeiten nutzen.{" "}
							</p>
							<p className="border mb-6 border-darkgreen p-4">
								<p className="font-bold text-[32px] mb-6 mt-12 leading-[42px]">5. Recht auf Widerspruch (Art. 21 DSGVO) </p>
								<p className="mb-6">
									Sofern die Verarbeitung auf unseren berechtigten Interessen (Art. 6 Abs. 1 f) DSGVO) beruht, haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen
									Situation ergeben, gegen die Verarbeitung jederzeit Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
								</p>
							</p>
							<p className="font-bold text-[32px] mb-6 mt-12 leading-[42px]">6. Weitere Betroffenenrechte</p>
							<p className="mb-6">Sie können mit Bezug auf Sie betreffende personenbezogene Daten weitere Rechte geltend machen: </p>
							<ul className="list-inside list-disc">
								<li className="py-2">Recht auf Auskunft (Art. 15 DSGVO)</li>
								<li className="py-2">Recht auf Berichtigung (Art. 16 DSGVO) </li>
								<li className="py-2">Recht auf Löschung ("Recht auf Vergessenwerden") (Art. 17 DSGVO) </li>
								<li className="py-2">Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO) </li>
								<li className="py-2">Recht auf Datenübertragbarkeit (Art. 20 DSGVO) </li>
							</ul>
							<p className="mb-6">Zudem haben Sie das Recht auf Beschwerde bei einer Datenschutz-Aufsichtsbehörde (Art. 77 DSGVO). </p>
							<p className="mb-6 font-bold">7. Automatisierte Entscheidungsfindung / Profiling </p>
							<p className="mb-6">Eine automatisierte Entscheidungsfindung bzw. Profiling finden nicht statt. </p>
							<p className="mb-6 font-bold">8. Versionierung </p>
							<p className="mb-6">Letzte Aktualisierung: März 2024 </p>
							<div className="text-center pt-5 py-10">
								<Link to="/" className="btn btn-invert mt-8">
									Zurück
								</Link>
							</div>
						</div>
					</div>
				</div>
			</>
		</div>
	)
}
export default Privacy
