import { useContext } from "react"
import { Context } from "../../store/store"
import ElemSelectProduct from "../elements/SelectProduct"
import ElemCheckbox from "../elements/Checkbox"
import ElemInput from "../elements/Input"
import { QUESTIONTRANSLATION } from "../../config"

interface ProductsProps {
	formElementChanged: Function
	validation: Object
}
const Product: React.FC<ProductsProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<section className="relative max-w-[1000px] mx-auto mt-4 lg:mt-20 bg-white p-4 border-[2px] border-darkgreen rounded-[8px]">
			<h3 className="text-darkgreen text-[32px] mt-2 lg:mt-6 mb-12 font-MomentsBold">Bevor du absendest</h3>

			<div className="grid gap-1 grid-cols-1 items-start mb-2 sm:mb-12 max-w-[750px] mx-auto">
				<ElemSelectProduct
					label={"Hättest du das Produkt auch ohne die Aktion gekauft?"}
					props={{
						name: "question1",
						value: globalState.question1,
						onChange: e => formElementChanged(e, "question1"),
					}}
					hasError={validation["invalid_question1"] ? true : false}
					className="col-span-2 md:col-span-1 text-left text-[13px] lg:text-[18px]"
					errMsg={validation["invalid_question1_errMsg"] || "Bitte Marke auswählen.*"}
					errorMsgPadding=""
				>
					<>
						<option value="">Bitte auswählen</option>
						<option value="a">Ja</option>
						<option value="b">Nein</option>
						<option value="c">keine Angabe</option>
					</>
				</ElemSelectProduct>
				<ElemSelectProduct
					label={"Hast du aufgrund der Aktion mehr Produkte gekauft als üblich?"}
					props={{
						name: "question2",
						value: globalState.question2,
						onChange: e => formElementChanged(e, "question2"),
					}}
					hasError={validation["invalid_question2"] ? true : false}
					className="col-span-2 md:col-span-1 text-left text-[13px] lg:text-[18px]"
					errMsg={validation["invalid_question2_errMsg"] || "Bitte beantworte die Frage."}
					errorMsgPadding="mt-10 2sm:mt-4 lg:mt-0 "
				>
					<>
						<option value="">Bitte auswählen</option>
						<option value="a">Ja</option>
						<option value="b">Nein</option>
						<option value="c">keine Angabe</option>
					</>
				</ElemSelectProduct>
				<ElemSelectProduct
					label={"Wie bist du auf die Aktion aufmerksam geworden?"}
					props={{
						name: "question3",
						value: globalState.question3,
						onChange: e => formElementChanged(e, "question3"),
					}}
					hasError={validation["invalid_question3"] ? true : false}
					className="col-span-2 md:col-span-1 text-left text-[13px] lg:text-[18px]"
					errMsg={validation["invalid_question3_errMsg"] || "Bitte beantworte die Frage."}
					errorMsgPadding="mt-10 2sm:mt-4 lg:mt-0 "
				>
					<>
						<option value="">Bitte auswählen</option>
						<option value="a">Packung im Supermarkt</option>
						<option value="b">Werbung im Supermarkt</option>
						<option value="c">Erst zuhause</option>
						<option value="d">Dr. Oetker Homepage</option>
						<option value="e">Social Media</option>
						<option value="f">Familie & Freunde</option>
						<option value="g">Deal-Seiten </option>
						<option value="h">Sonstiges </option>
						<option value="i">Keine Angaben</option>
					</>
				</ElemSelectProduct>
				<p className="text-[14px] text-center">Die Beantwortung der Fragen ist freiwillig.</p>
			</div>
		</section>
	)
}

export default Product
