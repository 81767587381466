import { useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import axios from "axios"
import { getEnvConfig } from "../env"
import Hero from "../components/home/Hero"

function NewsletterConfirm() {
	const env = getEnvConfig()
	const [searchParams] = useSearchParams()
	const confirmationid = searchParams.get("hash")
	const [confirmSuccess, setConfirmSuccess] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}
	const confirmNewsletter = () => {
		axios
			.post(env.apibase + "confirmNewsletter.php?hash=" + confirmationid)
			.then(res => {
				if (res.data.doubleOptInConfirmed) {
					setConfirmSuccess(true)
				}
			})
			.catch(error => {
				setErrorMessage("Du hast dich bereits erfolgreich dür den Newsletter angemeldet")
				setConfirmSuccess(false)
			})
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-green">
			<Hero page="danke" />
			<div className="text-center relative">
				<div className=" max-w-[1400px]  mx-auto">
					{confirmSuccess ? (
						<>
							<h2 className="h2 px-4 mt-12 lg:mt-24 max-w-[80%] lg:max-w-[65%] mx-auto lg:leading-[62px]">Vielen Dank, Du hast dich erfolgreich für den Newsletter angemeldet.</h2>
							<p className="text-black mt-6 lg:mt-12 text-sm sm:text-lg pt-4 px-4 lg:max-w-[65%] mx-auto"></p>
							<div className="text-center pt-6 lg:pt-12 mb-12 pb-10 px-4">
								<Link to="/" className="btn ">
									Zurück zum Gewinnspiel
								</Link>
							</div>
						</>
					) : (
						<>
							<h2 className="h2 px-4 mt-12 lg:mt-24 max-w-[80%] lg:max-w-[65%] mx-auto lg:leading-[62px]">Fast geschafft!</h2>
							<p className="text-black mt-6 mb-6 lg:mt-4 text-sm sm:text-lg pt-4 px-4 lg:max-w-[65%] mx-auto">
								{errorMessage ? errorMessage : <span>Klicke einfach auf den untenstehenden Button, um die Registrierung für unseren Radeberger Newsletter abzuschließen.</span>}
							</p>
							<div className="text-center pt-6 lg:pt-12 mb-12 pb-10 px-4">
								{errorMessage ? (
									<Link to="/" className="btn ">
										Zurück zum Gewinnspiel
									</Link>
								) : (
									<a className="btn" onClick={() => confirmNewsletter()}>
										Newsletter bestätigen
									</a>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
export default NewsletterConfirm
