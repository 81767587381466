import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import Slider from "react-slick"

const Sogehts: React.FC = () => {
	const sliderRef: any = useRef()
	const [arrowVisibilityPrev, setArrowVisbilityPrev] = useState(false)
	const [arrowVisibilityNext, setArrowVisbilityNext] = useState(true)

	let settings = {
		dots: false,
		accessibility: true,
		speed: 1500,
		arrows: false,
		slidesToScroll: 1,
		slidesToShow: 3,
		infinite: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					arrows: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					nextArrow: <CustomNextArrow />,
					prevArrow: <CustomPrevArrow />,
				},
			},
			{
				breakpoint: 768,
				settings: {
					arrows: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					nextArrow: <CustomNextArrow />,
					prevArrow: <CustomPrevArrow />,
				},
			},
		],
		afterChange: current => {
			switch (current) {
				case 0:
					setArrowVisbilityPrev(false)
					break
				case 2:
					setArrowVisbilityNext(false)
					// code block
					break
				default:
					setArrowVisbilityPrev(true)
					setArrowVisbilityNext(true)
			}
		},
	}
	function CustomNextArrow(props) {
		const { className, style, onClick } = props
		return (
			<div
				className={className + (arrowVisibilityNext ? " flex" : " hidden")}
				style={{
					alignItems: "center",
					justifyItems: "center",
					width: "40px",
					height: "40px",
					position: "absolute",
					right: "20px",
					backgroundImage: "none",
				}}
				onClick={onClick}
			>
				<svg width="30" height="30" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M2 2L9 9.5L2 17" stroke="#2E665E" stroke-width="4" stroke-linecap="round" />
				</svg>
			</div>
		)
	}

	function CustomPrevArrow(props) {
		const { className, style, onClick } = props
		return (
			<div
				className={className + (arrowVisibilityPrev ? " flex" : " hidden")}
				style={{ alignItems: "center", justifyItems: "end", width: "40px", height: "40px", position: "absolute", left: "0px", backgroundImage: "none" }}
				onClick={onClick}
			>
				<svg width="30" height="30" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M2 2L9 9.5L2 17" stroke="#2E665E" stroke-width="4" stroke-linecap="round" />
				</svg>
			</div>
		)
	}
	return (
		<div className=" bg-[url(/src/bgimages/bg-sogehts.png)] bg-cover z-12 relative -mt-8 ">
			<div className="pt-32 lg:pt-48 max-w-[1600px] relative  mx-auto before:content-[''] before:absolute lg:before:w-[430px] before:w-[220px] before:h-[140px] lg:before:h-[300px] before:bg-[url(/src/bgimages/icon-sogehts.png)] before:bg-cover before:-translate-x-[50%]  before:left-[50%] before:lg:left-[20%] lg:before:-top-32 before:-top-12 ">
				<div className="text-center relative z-0 -mt-[1px] md:py-8 md:pt-0 js-sogehts"></div>
				<div className="text-center mx-auto max-w-[1250px] lg:px-6">
					<Slider ref={sliderRef} {...settings}>
						<div className="grid items-center justify-items-center relative mx-auto">
							<img
								src={process.env.PUBLIC_URL + "/images/sogehts-step-1.png"}
								className="w-full text-center mx-auto md:mx-0 md:text-left h-auto max-w-[300px] md:max-w-[350px]"
								alt=""
							/>
							<div className="before:bg-[url('/src/bgimages/sogehts-number-1.png')] before:content-[''] lg:before:w-[100px] before:w-[80px] before:h-[80px] before:absolute lg:before:h-[100px] before:left-12 lg:before:-left-2 before:bg-contain before:z-[50] before:bg-no-repeat before:-mt-[80px] lg:before:-mt-[100px]">
								<div className=" px-6 md:pl-2 pt-4 text-center md:text-left text-black text-[17px]">
									<p className="font-MomentsText ">Dr. Oetker Intermezzo</p>
									<p className="text-darkgreen text-[29px] leading-[34px] ml-4 mt-2 font-MomentsBold">Aktionspackung(en) kaufen</p>
								</div>
							</div>
						</div>
						<div className="grid items-center justify-items-start relative -translate-y-[0px]">
							<div className="">
								<div className="before:bg-[url('/src/bgimages/sogehts-number-2.png')] before:content-[''] lg:before:w-[100px] before:w-[80px] before:h-[80px] before:absolute lg:before:h-[100px] before:left-12 lg:before:-left-12 before:bg-contain before:z-[50] before:bg-no-repeat before:mt-[80px] lg:before:mt-[170px]"></div>
								<div className=" px-6 md:pl-2 pt-4 text-center md:text-left text-black text-[17px]">
									<p className="text-darkgreen text-[29px]  mt-2 font-MomentsBold">Promotioncode(s) eingeben</p>
									<p className=" mt-2 font-MomentsText ">von der Innenseite der Aktionspackung(en) </p>
								</div>
								<img
									src={process.env.PUBLIC_URL + "/images/sogehts-step-2.png"}
									className="w-full text-center mx-auto md:mx-0 md:text-left h-auto max-w-[300px] md:max-w-[350px]"
									alt=""
								/>
							</div>
						</div>
						<div className="grid items-center justify-items-center relative">
							<img
								src={process.env.PUBLIC_URL + "/images/sogehts-step-3.png"}
								className="w-full text-center mx-auto md:mx-0 md:text-left h-auto max-w-[300px] md:max-w-[350px]"
								alt=""
							/>
							<div className="before:bg-[url('/src/bgimages/sogehts-number-3.png')] before:content-[''] lg:before:w-[120px] before:w-[100px] before:h-[100px] before:absolute lg:before:h-[120px] before:left-4 lg:before:-left-8 before:bg-contain before:z-[50] before:bg-no-repeat before:mt-[80px] lg:before:mt-[20px]"></div>
							<div className="">
								<div className=" px-6 md:pl-2 pt-4 text-center md:text-left text-black text-[18px] md:max-w-[80%] lg:ml-[80px]">
									<p className="font-MomentsText ">Je nach Anzahl der eingegebenen Codes bis zu</p>
									<p className="text-darkgreen text-[29px] mt-2 mb-6 font-MomentsBold">5 Euro zurückerhalten</p>
									<p>1x kaufen = 0,60 €</p>
									<p>2x kaufen = 1,40 €</p>
									<p>3x kaufen = 2,40 €</p>
									<p>4x kaufen = 3,60 €</p>
									<p>5x kaufen = 5,00 €</p>
								</div>
							</div>
						</div>
					</Slider>
				</div>
				<div className="text-center mx-auto max-w-[1400px] mb-12 pb-12 lg:mb-0 lg:pb-0">
					<div className="text-center md:text-left px-4  text-black py-[40px] md:py-[90px] font-PoppinsRegular text-[17px] md:text-[18px] opacity-50 lg:max-w-[60%]">
						<p className="lg:mb-12 font-MomentsText">
							Einlösungen sind bis zum 11.08.2024 möglich. Pro IBAN darf nur einmal an der Cashback-Aktion teilgenommen werden, wobei maximal 5 Promotioncodes hochgeladen werden
							können. Weitere Informationen in den{" "}
							<Link to="/teilnahmebedingungen" className="underline">
								Teilnahmebedingungen
							</Link>
							.*
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Sogehts
