import { Link } from "react-router-dom"

const MobileMenu = ({ isPromoOver, setShowMenu, scrollToAnchor }) => {
	const isPromoStarts = parseInt(Math.floor(Date.now() / 1000).toString()) > 1723413600
	return (
		<div className=" fixed lg:absolute top-0  w-[100vw] h-[100vh] lg:h-[700px] z-[300] bg-white text-left items-center max-w-[1400px] mx-auto">
			<div className="flex align-middle relative h-[100vh]  justify-items-center items-center lg:pt-0">
				<img
					src={process.env.PUBLIC_URL + "/images/close-btn.svg"}
					alt="Menu"
					loading="lazy"
					width="40"
					height="40"
					className=" w-[25px] cursor-pointer absolute right-8 top-8"
					onClick={() => setShowMenu(false)}
				/>
				<ul className="mx-auto w-[60%] mx-auto">
					{!isPromoStarts && (
						<>
							<li className="my-4 font-MomentsText">
								<Link
									to="/"
									onClick={() => {
										scrollToAnchor("sogehts")
										setShowMenu(false)
										try {
											// eslint-disable-next-line
											let dataLayer = window["dataLayer"] || []
											dataLayer.push({ event: "header", link: "sogehts" })
										} catch (e) {}
									}}
									className="header-item block text-black w-full text-left font-MomentsText border-b-[1px] border-white py-[10px] lg:py-[10px]"
								>
									So geht's
								</Link>
							</li>
							<li className="my-4 font-MomentsText">
								<Link
									to="/"
									onClick={() => {
										scrollToAnchor("mitmachen")
										setShowMenu(false)
										try {
											// eslint-disable-next-line
											let dataLayer = window["dataLayer"] || []
											dataLayer.push({ event: "header", link: "jetztmitmachen" })
										} catch (e) {}
									}}
									className="header-item block text-black w-full text-left font-MomentsText py-[10px]"
								>
									Jetzt Code einlösen
								</Link>
							</li>
						</>
					)}
					<li className="my-4 font-MomentsText">
						<Link
							to={process.env.PUBLIC_URL + "/teilnahmebedingungen"}
							className="header-item block text-black w-full text-left font-MomentsText  py-[10px]"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "teilnahmebedingungen" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							Teilnahmebedingungen
						</Link>
					</li>
					<li className="my-4 font-MomentsText">
						<Link
							to={process.env.PUBLIC_URL + "/faq"}
							className="header-item block text-black w-full text-left font-MomentsText py-[10px]"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "faq" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							FAQ
						</Link>
					</li>
					<li className="my-4 font-MomentsText">
						<Link
							to={process.env.PUBLIC_URL + "/kontakt"}
							className="header-item block text-black w-full text-left font-MomentsText py-[10px]"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "kontakt" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							Kontakt
						</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default MobileMenu
