import React, { useEffect, useContext } from "react"
import Hero from "../components/home/Hero"
import { Context } from "../store/store"
import { Link } from "react-router-dom"

const Confirm: React.FC = () => {
	const { globalState, dispatch } = useContext(Context)
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="">
			<Hero page="danke" />
			<div className="text-center relative bg-[url(/src/bgimages/bg-sogehts.png)] bg-cover ">
				<div className=" max-w-[1400px]  mx-auto -mt-12 pt-32 lg:pt-42">
					<p className="text-darkgreen text-[24px] lg:text-[32px] ml-4 mt-2 lg:pt-12 leading-[28px] lg:leading-[42px] font-MomentsBold ">
						Die Daten wurden erfolgreich übermittelt. <br />
						Du erhältst per E-Mail eine Teilnahmebestätigung.
					</p>
					<p className="text-black text-[16px] lg:text-[28px] ml-4 lg:mt-12 pt-4 lg:leading-[42px] mb-12 pb-24 lg:pb-0 font-MomentsText ">
						Viele Grüße
						<br /> Dein Dr. Oetker Team
					</p>
				</div>
			</div>
		</div>
	)
}
export default Confirm
