import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import AccordionItem from "../components/elements/AccordionItem"
import Hero from "../components/home/Hero"
import Header from "../components/Header"

const FAQ: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-white">
			<Hero page="terms" />
			<>
				<div className="container max-w-[800px] mx-auto px-4 ">
					<h2 className="h2 mb-4 lg:mb-16 text-center mt-4 lg:mt-16 lg:leading-[52px] text-[36px] leading-[42px]">
						<div className="text-darkgreen  lg:mb-16 font-Atma font-bold ">FAQ </div>
					</h2>
					<h3 className="h3 lg:mb-16 text-center ">
						<div className="text-darkgreen mb-6 lg:mb-16 font-Atma font-bold font-MomentsText">1. Allgemeine Informationen (zur Aktion) </div>
					</h3>
					<AccordionItem headline="Worum geht es bei der aktuellen Dr. Oetker Intermezzo Cashback-Aktion?" num="1.">
						<p className="text-black">
							Die Dr. August Oetker Nahrungsmittel KG führt im Zeitraum vom 01.04.2024 bis zum 11.08.2024 eine Cashback-Aktion mit Staffelrabatt für die Produkte von Dr. Oetker
							Intermezzo durch.
						</p>
					</AccordionItem>
					<AccordionItem headline="Wer darf an der Aktion teilnehmen?" num="2.">
						<p className="text-black">Teilnahmeberechtigt sind alle Personen ab 18 Jahren mit Wohnsitz in Deutschland und Bankkonto im SEPA-Raum.</p>
					</AccordionItem>
					<AccordionItem headline="Welche Produkte nehmen an der Aktion teil?" num="3.">
						<>
							<p className="text-black py-4">Die Aktion gilt für die folgenden Produkte der Produktlinie Dr. Oetker Intermezzo:</p>

							<ul>
								<li className="mb-2">
									<ul>
										<li>Intermezzo Champignon Steinpilz-Ricotta </li>
										<li>Intermezzo Tomate Mozzarella</li>
										<li>Intermezzo Salami Calabrese </li>
										<li>Intermezzo Thunfisch MSC mit Zwiebeln</li>
										<li>Intermezzo Schinken mit Sauerrahm</li>
										<li>Intermezzo Hot Chili mit Pepperoni-Salami</li>
									</ul>
								</li>
							</ul>
						</>
					</AccordionItem>
					<AccordionItem headline="Welchen Betrag erhalte ich zurückerstattet?" num="4.">
						<p className="text-black">
							Die Höhe der Cashback-Beträge ist abhängig von der Anzahl der gekauften Aktionspackungen. Die Cashback-Staffelung lautet wie folgt: 1x kaufen = 0,60 €; 2x kaufen =
							1,40 €; 3x kaufen = 2,40 €; 4x kaufen = 3,60 €; 5x kaufen = 5,00 €.
						</p>
					</AccordionItem>
					<AccordionItem headline="Wie erkenne ich die Dr. Oetker Intermezzo Aktionspackungen?" num="5.">
						<p className="text-black">Die Aktionspackungen lassen sich durch einen Störer auf der Verpackung identifizieren:</p>
						<img src={process.env.PUBLIC_URL + "/images/intermezzo-faq.png"} />
					</AccordionItem>
					<AccordionItem headline="Wo finde ich die Dr. Oetker Intermezzo Aktionsprodukte?" num="6.">
						<p className="text-black">
							Die Dr. Oetker Intermezzo Aktionsprodukte können im deutschen stationären Lebensmittelhandel erworben werden. Es kann nicht garantiert werden, dass alle
							Lebensmittelverkaufsstellen alle Dr. Oetker Produkte im Sortiment führen. Dr. Oetker ist für die Verfügbarkeit der Aktionsprodukte im Handel während des
							Aktionszeitraums nicht verantwortlich. Sollte das vom Teilnehmer gewünschte Aktionsprodukt im Aktionszeitraum nicht verfügbar sein, entstehen dadurch keine Ansprüche
							gegen Dr. Oetker.
						</p>
					</AccordionItem>
					<h3 className="h3 my-16 text-center ">
						<div className="text-darkgreen mb-16 font-Atma font-bold">2. Durchführung und Abwicklung</div>
					</h3>
					<AccordionItem headline="Wie kann ich an der Aktion teilnehmen?" num="7.">
						<p className="text-black">
							Dr. Oetker Intermezzo Aktionspackung(en) kaufen, Aktionscode(s) von der Innenseite der Aktionspackung(en) online unter{" "}
							<a
								className="underline"
								href="https://www.intermezzo-cashback.de"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "faq", link: "startseite" })
									} catch (e) {}
								}}
							>
								www.intermezzo-cashback.de
							</a>{" "}
							hochladen. <br /> eintragen. Das Kontaktformular und Bankinformationen ausfüllen und auf „Jetzt absenden“ klicken. Die Rückerstattung erfolgt nach erfolgreicher
							Teilnahme binnen 30 Tagen auf das angegebene Bankkonto.
						</p>
					</AccordionItem>
					<AccordionItem headline="In welchem Zeitraum kann an der Aktion teilgenommen werden?" num="8.">
						<p className="text-black">Die Dr. Oetker Intermezzo Cashback-Aktion findet im Zeitraum vom 01.04.2024 bis zum 11.08.2024 statt.</p>
					</AccordionItem>
					<AccordionItem headline="Wo finde ich meine IBAN/BIC?" num="9.">
						<p className="text-black">
							Deine persönliche IBAN-Nummer und den BIC findest du auf der Vorder- und/oder Rückseite deiner Bankkarte sowie im Online-Banking. Die Zahlenfolge der IBAN für
							Deutschland beginnt stets mit DE und enthält 22 Zeichen.
						</p>
					</AccordionItem>
					<AccordionItem headline="Ich habe versehentlich einen Fehler bei der Dateneingabe gemacht, was kann ich tun?" num="10.">
						<p className="text-black">
							Bitte wende dich umgehend an den Dr. Oetker Aktions-Kundenservice telefonisch unter 0521/33 72 17 17 (erreichbar von Montag bis Freitag von 08:30 - 17:30 Uhr) oder
							per E-Mail an{" "}
							<a
								className="underline"
								href="mailto:intermezzo@oetker-promotions.de"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "faq", link: "email" })
									} catch (e) {}
								}}
							>
								intermezzo@oetker-promotions.de
							</a>
							. Wenn die Rücküberweisung noch nicht getätigt wurde, können wir deine Daten korrigieren.
						</p>
					</AccordionItem>
					<AccordionItem headline="Ich habe beim Einlösen der Codes ein Problem, was kann ich tun?" num="11.">
						<p className="text-black">
							Bitte setze dich mit uns über{" "}
							<a
								className="underline"
								href="mailto:intermezzo@oetker-promotions.de"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "faq", link: "email" })
									} catch (e) {}
								}}
							>
								intermezzo@oetker-promotions.de
							</a>{" "}
							in Verbindung und beschreibe uns das Problem. Solltest du eine Fehlermeldung angezeigt bekommen, sende uns hiervon gerne einen Screenshot sowie Angaben zum
							verwendeten Browser und Gerät mit.
						</p>
					</AccordionItem>
					<AccordionItem headline="Ist eine Mehrfach-Teilnahme möglich?" num="12.">
						<p className="text-black">
							Pro IBAN darf nur einmal an der Cashback-Aktion teilgenommen werden, wobei maximal fünf Promotioncodes hochgeladen werden können. Jeder Promotioncode kann nur einmal
							genutzt werden. Nicht mit anderen Rabattaktionen kombinierbar.
						</p>
					</AccordionItem>
					<AccordionItem headline="Ich habe den Code nicht mehr, was kann ich tun bzw. kann ich trotzdem teilnehmen?" num="13.">
						<p className="text-black">Leider nein, die Teilnahme ohne Code ist nicht möglich.</p>
					</AccordionItem>
					<AccordionItem headline="Kann ich auch über den Postweg teilnehmen?" num="14.">
						<p className="text-black">
							An der Dr. Oetker Intermezzo Cashback Aktion kann ausschließlich online unter{" "}
							<a
								className="underline"
								href="https://www.intermezzo-cashback.de"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "faq", link: "startseite" })
									} catch (e) {}
								}}
							>
								www.intermezzo-cashback.de
							</a>{" "}
							teilgenommen werden. Eine Teilnahme über den Postweg ist leider nicht möglich.
						</p>
					</AccordionItem>
					<h3 className="h3 my-16 text-center ">
						<div className="text-darkgreen mb-16 font-Atma font-bold">3. Nach der Teilnahme</div>
					</h3>
					<AccordionItem headline="Wie kann ich sicher sein, dass meine Teilnahme an der Aktion erfolgreich war? " num="15.">
						<p className="text-black">Nach dem erfolgreichen Absenden der Teilnahmedaten erhältst du eine Teilnahmebestätigung an deine angegebene E-Mail Adresse.</p>
					</AccordionItem>
					<AccordionItem headline="Wie lange dauert es, bis ich mein Geld zurückerhalte?" num="16.">
						<p className="text-black">
							Der genannte Cashback-Betrag entsprechend der Staffelung wird in Euro auf das vom Teilnehmer angegebene Konto (SEPA-Raum) überwiesen. Die Rückerstattung erfolgt nach
							erfolgreicher Teilnahme binnen 30 Tagen auf das angegebene Bankkonto. Eventuelle Gebühren, die die kontoführende Bank des Teilnehmers für den Empfang des Geldes oder
							die Umrechnung in andere Währungen berechnet, hat der Kontoinhaber selbst zu tragen. Der Teilnehmer muss ebenso die Kosten tragen, die mit der Teilnahme über das
							Internet verbunden sind (Nutzungsentgelt).
						</p>
					</AccordionItem>
					<AccordionItem headline="Ist der Status der Rückerstattung einsehbar?" num="17.">
						<p className="text-black">
							Leider ist der Status der Rückerstattung nicht online abrufbar. Um Auskunft über den Status der Rückerstattung zu erhalten, kannst du dich unter Angabe deines Namens
							per E-Mail an intermezzo@oetker-promotions.de oder telefonisch unter 0521/33 72 17 17 an unseren Aktions-Kundenservice wenden.
						</p>
					</AccordionItem>
					<AccordionItem headline="Ich habe noch keine Rückzahlung bekommen." num="18.">
						<p className="text-black">
							Grundsätzlich versuchen wir jeder teilnehmenden Person so schnell wie möglich die Rückzahlung auf das Konto zu überweisen. Bei erfolgreicher Teilnahme erfolgt dies
							innerhalb von 30 Tagen. Hast du in diesem Zeitrahmen keine Rückzahlung erhalten, sende uns bitte eine E-Mail an{" "}
							<a
								className="underline"
								href="mailto:intermezzo@oetker-promotions.de"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "faq", link: "email" })
									} catch (e) {}
								}}
							>
								intermezzo@oetker-promotions.de
							</a>{" "}
							unter Angabe des vollständigen Namens und der E-Mail-Adresse. Wir werden uns schnellstmöglich mit dir in Verbindung setzen.
						</p>
					</AccordionItem>
					<h3 className="h3 my-16 text-center ">
						<div className="text-darkgreen mb-16 font-Atma font-bold">4. Sonstiges</div>
					</h3>
					<AccordionItem headline="Was passiert mit meinen Daten?" num="19.">
						<p>
							Ausführlich Informationen zum Datenschutz findest du{" "}
							<Link className="underline" to="/datenschutz">
								hier
							</Link>
							.
						</p>
					</AccordionItem>
					<div className="text-darkgreen pt-8 text-center">
						<p className="mb-6 text-darkgreen text-[18px] lg:text-[22px]">Du hast keine Antwort auf deine Frage erhalten?</p>
						<p className="text-darkgreen text-[18px] lg:text-[22px]">
							Das Service-Team ist von Montag bis Freitag von 08:30 - 17:30 Uhr telefonisch unter 0521/33 72 17 17 erreichbar oder per E-Mail unter{" "}
							<a
								className="underline"
								href="mailto:intermezzo@oetker-promotions.de"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "faq", link: "email" })
									} catch (e) {}
								}}
							>
								intermezzo@oetker-promotions.de
							</a>{" "}
							.
						</p>
					</div>
				</div>
			</>
			<div className="text-center pt-5 py-10">
				<Link to="/" className="btn btn-invert mt-8">
					Zurück
				</Link>
			</div>
		</div>
	)
}
export default FAQ
