import { useAutoAnimate } from "@formkit/auto-animate/react"
import { useContext, useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { ERRORTYPES, MAX_PRODUCT_PRICE, WINNINGTEXT } from "../../config"
import { Context } from "../../store/store"
import Alert from "../elements/Alert"
import Spinner from "../elements/Spinner"
import BankAccount from "./BankAccount"
import Legal from "./Legal"
import { placeOrder } from "./OrderFct"
import Person from "./Person"
import Product from "./Product"
import CodeValidation from "./CodeValidation"
import ElemSelect from "../elements/Select"
const Form = () => {
	const { globalState, dispatch } = useContext(Context)
	const [validation, setValidation] = useState({})
	const navigate = useNavigate()
	const [formError, setFormError] = useState<ERRORTYPES | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const resetParticipationState = () => {
		dispatch({
			type: "RESET",
		})
	}

	const ageCheck = timeValue => {
		if (timeValue) {
			let date = new Date(timeValue.split(" ")[0])
			let now = new Date()

			if (date.getFullYear() <= 1900) {
				return true
			}

			const eighteenYears = (567648000 + 432000) * 1000 // 18 Jahre - 5 Tage // ??? schaltjahre oder so

			if (now.getTime() - date.getTime() > eighteenYears) {
				return false
			} else {
				return true
			}
		} else {
			return true
		}
	}

	function formElementChanged(e, key) {
		// special handling for checkboxes
		if (e.target.type === "checkbox") {
			e.target.value = e.target.checked
		}

		dispatch({
			type: "SET_ORDER_ENTRY",
			key: key,
			value: e.target.value
				.replace(/(<([^>]+)>)/gi, "")
				.replace("https://www", "")
				.replace("mailto:", ""),
		}) // strip HTML tags
		let isFieldInvalid = false
		if (key === "age") {
			isFieldInvalid = ageCheck(e.target.value)
		}

		const doc: any = document
		const elem = doc.querySelector("[name='" + key + "']:valid")
		if (elem === null) {
			isFieldInvalid = true
		}
		// check validation
		setValidation({ ...validation, ["invalid_" + key]: isFieldInvalid })
	}

	useEffect(() => {
		resetParticipationState()
	}, [])
	function submitOrder() {
		// create order part
		const orderSuccessCallback = extId => {
			setIsLoading(false)
			if (extId) {
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "particpate", "form-submit": false, field_error: "send" })
				} catch (e) {}

				navigate("/danke")
			} else {
				setFormError(ERRORTYPES.SERVER)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "participate", "form-submit": true, package: globalState.package })
				} catch (e) {}
			}
		}
		const orderErrCallback = (tooManyAttemps: false, invalidCaptcha: false) => {
			setIsLoading(false)

			//TODO: Handling needs to be switched to error codes (BE part still open)
			if (tooManyAttemps) {
				setFormError(ERRORTYPES.MAXTRIES)
			} else if (invalidCaptcha) {
				setValidation({ ...validation, invalid_captcha: true })
				setFormError(null)
				scrollToError()
			} else {
				setFormError(ERRORTYPES.SERVER)
			}
			try {
				// eslint-disable-next-line
				let dataLayer = window["dataLayer"] || []
				dataLayer.push({ event: "form-submit", "form-name": "participate", "form-submit": true, package: globalState.package })
			} catch (e) {}
		}

		if (allInputFieldsValid()) {
			setIsLoading(true)
			setFormError(null)
			placeOrder(globalState, setFormValidation, orderSuccessCallback, orderErrCallback)
		} else {
			setFormError(ERRORTYPES.FORMVALIDATION)
			scrollToError()
		}
	}

	function isReceiptAndFotoGiven() {
		if (globalState.receiptReference && globalState.productImg) {
			return true
		}
		return false
	}

	function isBicRequired() {
		return globalState.iban && globalState.iban.length > 2 && globalState.iban.substring(0, 2).toUpperCase() !== "DE"
	}

	function allInputFieldsValid() {
		// input names has to fit to variable classNames
		let isAllValid = true
		let invalidFields = {}

		// Special handing for bic. Bic is only for non DE IBANs required
		let fieldsToCheck: any = []
		if (isBicRequired()) {
			fieldsToCheck = Object.keys(globalState).filter(
				e =>
					e !== "package" &&
					e !== "captcha" &&
					e !== "optInTerms" &&
					e !== "optInAge" &&
					e !== "optInMarketing" &&
					e !== "captchaIdent" &&
					e !== "receiptReference" &&
					e !== "receiptFileName" &&
					e !== "code2" &&
					e !== "code1" &&
					e !== "code3" &&
					e !== "code4" &&
					e !== "code5" &&
					e !== "over18OptIn"
			)
		} else {
			fieldsToCheck = Object.keys(globalState).filter(
				e =>
					e !== "package" &&
					e !== "captchaIdent" &&
					e !== "bic" &&
					e !== "question3" &&
					e !== "question4" &&
					e !== "receiptReference" &&
					e !== "receiptFileName" &&
					e !== "code2" &&
					e !== "code3" &&
					e !== "code4" &&
					e !== "code5" &&
					e !== "code1" &&
					e !== "over18OptIn"
			)
		}

		for (let index = 0; index < fieldsToCheck.length; index++) {
			const key = fieldsToCheck[index]

			const doc: any = document
			const elem = doc.querySelector("[name='" + key + "']:valid")
			if (elem === null) {
				isAllValid = false
				invalidFields["invalid_" + key] = true
			}
		}

		if (Object.keys(invalidFields).length) {
			setValidation({ ...validation, ...invalidFields })
		}

		return isAllValid
	}

	function setFormValidation(data: {}) {
		setValidation({
			...validation,
			...data,
		})
		scrollToError()
	}

	function scrollToError() {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-formerror").getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY - 120,
					left: 0,
					behavior: "smooth",
				})
			} catch (error) {}
		}, 500)
	}
	return (
		<div className="bg-[url(/src/bgimages/bg-codes.png)] bg-cover">
			<form
				onSubmit={e => {
					e.preventDefault()
					submitOrder()
				}}
				noValidate={true}
				className=" max-w-[1400px] mx-auto"
			>
				<section className="">
					<div className={"px-4 lg:px-12 "}>
						<CodeValidation {...{ validation, formElementChanged }} />
						<div className="grid grid-cols-1 lg:grid-cols-[60%_40%] lg:gap-x-12">
							<div>
								<Person {...{ validation, formElementChanged }} />
								<BankAccount {...{ validation, formElementChanged }} />
							</div>
							<div>
								<Product {...{ validation, formElementChanged }} />
							</div>
						</div>
						<Legal {...{ validation, formElementChanged }} />

						<div className="max-w-[1000px] mx-auto pl-12 mt-6">
							<div className="text-left  text-white font-Quicksand">*Pflichtfelder</div>
						</div>
						<div className="mb-6  lg:mt-0 lg:px-0">{formError && <Alert className={"my-4"}>{formError}</Alert>}</div>
						<div className=" grid grid-cols-1 gap-2  mt-4  md:mt-0 self-end justify-self-center pb-12 px-12 lg:px-0">
							<button type="submit" className="btn" disabled={isLoading ? true : undefined}>
								{isLoading ? <Spinner /> : "Jetzt absenden"}
							</button>
						</div>
					</div>

					{/* {process.env.NODE_ENV === "development" && <>{JSON.stringify(globalState).replaceAll(",", ", ")}</>} */}
				</section>
			</form>
		</div>
	)
}

export default Form
