function Footer() {
	type TFooterLink = {
		text: string
		link: string
		target?: string
	}

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	const footerLinks: TFooterLink[] = [
		{
			text: "Datenschutz",
			link: process.env.PUBLIC_URL + "/datenschutz",
		},
		{
			text: "Impressum",
			link: process.env.PUBLIC_URL + "/impressum",
		},
	]

	return (
		<footer className="bg-green py-4 lg:py-0 lg:h-[50px] grid items-center ">
			<div className="px-4 grid items-center grid-cols-1 lg:grid-cols-1 ">
				<div className="text-center lg:text-right">
					<nav>
						<ul>
							{footerLinks.map((footerLink, i) => (
								<li key={i} className="inline-block">
									<a
										href={footerLink.link}
										target={footerLink.target}
										className="text-[16px] font-Roboto pl-2 pr-2 lg:pr-4 lg:pl-4 text-black hover:underline"
										onClick={() => track(footerLink.text)}
									>
										{footerLink.text}
									</a>
								</li>
							))}
						</ul>
					</nav>
				</div>
			</div>
		</footer>
	)
}

export default Footer
