import { useEffect } from "react"
import Hero from "../components/home/Hero"
import Sogehts from "../components/home/Sogehts"

function Promoover() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="">
			<Hero page="over" />
			<div className="text-center relative z-0   px-4 pt-6 lg:pt-12 pb-12 lg:pb-24 max-w-[1200px] mx-auto mt-12">
				<h2 className="h2 mt-12 font-MomentsText lg:mt-24 lg:leading-[60px] leading-[28px] text-[24px] font-bold lg:text-[53px] mb-12 text-darkgreen">
					Unsere Dr. Oetker Intermezzo Cashback-Aktion ist seit dem 11.08.2024 beendet.
				</h2>
				<p className=" mt-12 lg:mt-12  mb-12 text-darkgreen text-[26px] lg:text-[32px]">Du hast noch Fragen zur Aktion? </p>
				<div className="max-w-[400px] mx-auto">
					<p className="text-darkgreen text-[18px] lg:text-[24px] lg:leading-[28px]">
						Kontaktiere uns einfach per E-Mail unter{" "}
						<a href="mailto:intermezzo@oetker-promotions.de" className="underline">
							intermezzo@oetker-promotions.de
						</a>{" "}
						oder telefonisch unter 0521-33 72 17 17
					</p>
				</div>
				<h2 className="h2 mt-12 lg:mt-12  mb-12 text-darkgreen lg:leading-[60px] text-[24px] lg:text-[42px] font-MomentsText">Weitere tolle Aktionen findest du auf</h2>
				<div className="text-center pt-5 py-10">
					<a href="https://www.oetker.de" className="btn btn-invert mt-8">
						www.oetker.de
					</a>
				</div>
			</div>
		</div>
	)
}
export default Promoover
