import { useContext } from "react"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"

interface BankAccountProps {
	formElementChanged: Function
	validation: Object
}
const BankAccount: React.FC<BankAccountProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<>
			<div className="container">
				<h3 className="text-darkgreen text-[32px] mb-6 font-MomentsBold">Bankverbindung</h3>

				<div className="grid grid-cols-1 md:grid-cols-1 items-start mb-6  mx-auto">
					<ElemInput
						label={"IBAN*"}
						props={{
							type: "text",
							name: "iban",
							value: globalState.iban,
							required: "required",
							placeholder: "IBAN*",
							minLength: 16,
							maxLength: 31,
							onChange: e => formElementChanged({ target: { value: e.target.value.replace(" ", "").toUpperCase() } }, "iban"), // remove whitespaces
						}}
						hasError={validation["invalid_iban"] ? true : false}
						className=""
						errMsg={validation["invalid_iban_errMsg"] || "Bitte IBAN angeben. "}
					/>
					{globalState.iban && globalState.iban.length > 2 && globalState.iban.substring(0, 2).toUpperCase() !== "DE" && (
						<ElemInput
							label={"BIC*"}
							props={{
								type: "text",
								name: "bic",
								value: globalState.bic,
								placeholder: "BIC",
								required: "required",
								maxLength: 11,
								onChange: e => formElementChanged({ target: { value: e.target.value.replace(" ", "").toUpperCase() } }, "bic"),
							}}
							hasError={validation["invalid_bic"] ? true : false}
							className=""
							errMsg={validation["invalid_bic_errMsg"] || "Bitte BIC angeben. "}
						/>
					)}
				</div>
			</div>
		</>
	)
}

export default BankAccount
