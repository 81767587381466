import Header from "../Header"
import { Link } from "react-router-dom"
import React from "react"
import Sogehts from "./Sogehts"

interface HeroProps {
	page?: any
}
const Hero: React.FC<HeroProps> = ({ page }) => {
	function scrollToAnchor(anchor) {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-" + anchor).getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY,
					left: 0,
					behavior: "smooth",
				})

				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "header", link: anchor })
				} catch (e) {}
			} catch (error) {}
		}, 300)
	}
	return (
		<>
			<div className="bg-white relative top-0 mx-auto bg-[url(/src/bgimages/header-bg.png)] bg-cover md:bg-cover">
				<Header />
				<div className="hero-content pt-2 ">
					<div className="intermezzo max-w-[1600px] w-full grid grid-cols-1 lg:self-end mx-auto gap-12 md:mt-16 lg:mt-12 mb-12">
						<img
							src={process.env.PUBLIC_URL + "/images/logo-intermezzo.png"}
							alt="DrOetker-Intermezzo"
							className="hidden lg:block mt-32 md:mt-0 md:max-w-[500px] lg:max-w-[1000px] md:self-center lg:self-end md:justify-self-center lg:justify-self-end"
						/>
						<img
							src={process.env.PUBLIC_URL + "/images/packshots-mobile.png"}
							alt="DrOetker-Intermezzo"
							className=" lg:hidden mt-4 lg:mt-24 mr-6 md:mt-0 max-w-[250px] self-end justify-self-end"
						/>
						<img src={process.env.PUBLIC_URL + "/images/logo-intermezzo-mobile.png"} alt="" className="block md:hidden  max-w-[350px] mx-auto relative right-0 md:left-0 -mt-24" />
					</div>
					<div
						className={
							"bg-green border-t-[10px] border-darkgreen " +
							(page === "home" ? "pb-24 lg:pb-0" : "") +
							(page === "pre" ? "pb-12 lg:pb-0" : "") +
							(page === "danke" ? "pb-24 lg:pb-0" : "")
						}
					>
						<div className={"  max-w-[1600px] mx-auto grid lg:grid-cols-2 relative  md:pb-8 md:-mt-0 "}>
							<div className="hidden lg:block max-w-[1600px] mx-auto">
								<img
									src={process.env.PUBLIC_URL + "/images/packshots.png"}
									alt=""
									className="hidden lg:block max-w-[100%] lg:max-w-[90%] relative right-0 md:left-0 -mt-12 lg:-mt-64"
								/>
							</div>
							<div>
								<div className="grid grid-cols-1 items-center justify-items-center md:justify-items-start md:items-start">
									<div className=" md:mb-12 grid grid-cols-1 p-6 md:p-0   mb-6 lg:mb-12 -mt-12">
										<img src={process.env.PUBLIC_URL + "/images/bg-cash-icon.png"} alt="" className=" max-w-[300px] mx-auto md:max-w-[580px] lg:mb-12" />
									</div>
								</div>
								{page === "danke" && (
									<img
										src={process.env.PUBLIC_URL + "/images/icon-teilnahme.png"}
										alt=""
										className="max-w-[200px] md:max-w-[350px] absolute z-[20] right-0 left-[50%] -translate-x-[50%] md:-mt-24"
									/>
								)}
								{page === "pre" && (
									<img
										src={process.env.PUBLIC_URL + "/images/icon-promosoon.png"}
										alt=""
										className="max-w-[200px] lg:max-w-[350px] xl:max-w-[350px] absolute z-[20] right-0 left-[75%] mf:left-[660px] lg:left-[700px] -translate-x-[50%] -mt-[480px] lg:-mt-20 xl:-mt-32"
									/>
								)}
								{page === "over" && (
									<img
										src={process.env.PUBLIC_URL + "/images/icon-promoover.png"}
										alt=""
										className="max-w-[200px] md:max-w-[400px] absolute z-[20] right-0 left-[50%] -translate-x-[50%] -mt-12 md:-mt-24"
									/>
								)}
								{page === "home" && (
									<a
										className="btn normal-case inline-block mt-0 md:mb-6 md:mt-2 px-6 lg:px-12 ml-[35%] -translate-x-[30%] lg:ml-[15%]"
										onClick={() => scrollToAnchor("mitmachen")}
									>
										Jetzt Code einlösen!
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Hero
