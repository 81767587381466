import { useAutoAnimate } from "@formkit/auto-animate/react"
import { useContext, useState, useRef, useEffect } from "react"
import { Context } from "../../store/store"
import Popup from "../elements/Popup"
import ElemInput from "../elements/Input"
import { MINCODELETTERS } from "../../config"

import { checkValidCodes } from "../helper/codes.helper"

const CodeValidation = ({ formElementChanged, validation }) => {
	const { globalState, dispatch } = useContext(Context)
	const [code, setCode] = useState("")
	const [codeValid, setCodeValid] = useState("")
	const [showCodes, setShowCodes] = useState(1)

	async function checkCodes(name: string, val: any, e: any) {
		if (val.length == MINCODELETTERS) {
			let isCodeValid = await checkValidCodes(val)
			if (isCodeValid.data.valid) {
				dispatch({
					type: "SET_CODE",
					key: name,
					value: val,
				})
				e.target.setAttribute("value", e.target.value)
				e.target.classList.remove("error")
				e.target.classList.add("no-error")
			} else {
				dispatch({
					type: "SET_CODE",
					key: name,
					value: val,
				})
				e.target.classList.remove("no-error")
				e.target.classList.add("error")
			}
		} else {
			dispatch({
				type: "SET_CODE",
				key: name,
				value: "",
			})
			e.target.classList.remove("no-error")
			e.target.classList.remove("error")
		}
		console.log(globalState)
	}
	useEffect(() => {}, [showCodes])

	return (
		<>
			<section className="pt-4 relative js-mitmachen  before:content-[''] before:absolute before:w-[220px] lg:before:w-[385px] before:h-[130px] lg:before:h-[225px] before:bg-[url(/src/bgimages/icon-actionscodes.png)] before:bg-cover before:-translate-x-[50%] lg:before:-translate-x-0  before:left-[50%] lg:before:left-20  before:-top-32 lg:before:-top-32">
				<div className=" text-center">
					<div className="p-1 sm:p-8">
						<div className="grid grid-cols-1 lg:grid-cols-2 items-center max-w-[1600px] mx-auto">
							<div className="lg:text-left mt-2 mb-6 lg:mb-0 lg:mt-36 text-center lg:ml-24 text-[24px] font-MomentsText">
								<p>
									Der/die Promotioncode(s) befinden sich <br />
									auf der Innenseite der Aktionspackung(en).
								</p>
							</div>
							<div className="grid grid-cols-1 w-full lg:grid-cols-1 gap-4 lg:gap-2  text-center">
								<p className="text-darkgreen text-[32px] ml-4 mt-2 lg:mb-6 font-MomentsBold">Jetzt Code(s) eingeben</p>
								<ElemInput
									label={"Code*"}
									props={{
										type: "text",
										name: "code1",
										required: "required",
										minLength: 1,
										maxLength: 10,
										placeholder: "Code 1 eingeben*",
										onChange: e => checkCodes("code1", e.target.value, e),
									}}
									hasError={validation["invalid_code1"] ? true : false}
									className={"mt-6 lg:mt-0 text-center relative placeholder:text-center"}
									errMsg={validation["invalid_code1_errMsg"] || "Bitte gib einen Code ein"}
								/>
								<ElemInput
									label={"Code*"}
									props={{
										type: "text",
										name: "code2",
										minLength: 1,
										maxLength: 10,
										placeholder: "Code 2 eingeben",
										onChange: e => checkCodes("code2", e.target.value, e),
									}}
									hasError={validation["invalid_code2"] ? true : false}
									className={"lg:mt-0 text-center placeholder:text-center " + (showCodes > 1 ? " block" : " hidden")}
									errMsg={validation["invalid_code2_errMsg"] || "Bitte gib einen Code ein"}
								/>
								<ElemInput
									label={"Code*"}
									props={{
										type: "text",
										name: "code3",
										minLength: 1,
										maxLength: 10,
										placeholder: "Code 3 eingeben",
										onChange: e => checkCodes("code3", e.target.value, e),
									}}
									hasError={validation["invalid_code3"] ? true : false}
									className={" lg:mt-0 text-center placeholder:text-center " + (showCodes > 2 ? " block" : " hidden")}
									errMsg={validation["invalid_code3_errMsg"] || "Bitte gib einen Code ein"}
								/>
								<ElemInput
									label={"Code*"}
									props={{
										type: "text",
										name: "code4",
										minLength: 1,
										maxLength: 10,
										placeholder: "Code 4 eingeben",
										onChange: e => checkCodes("code4", e.target.value, e),
									}}
									hasError={validation["invalid_code4"] ? true : false}
									className={"lg:mt-0 text-center placeholder:text-center " + (showCodes > 3 ? " block" : " hidden")}
									errMsg={validation["invalid_code4_errMsg"] || "Bitte gib einen Code ein"}
								/>
								<ElemInput
									label={"Code*"}
									props={{
										type: "text",
										name: "code5",
										minLength: 1,
										maxLength: 10,
										placeholder: "Code 5 eingeben",
										onChange: e => checkCodes("code5", e.target.value, e),
									}}
									hasError={validation["invalid_code5"] ? true : false}
									className={"mt-6 lg:mt-0 text-center placeholder:text-center " + (showCodes > 4 ? " block" : " hidden")}
									errMsg={validation["invalid_code5_errMsg"] || "Bitte gib einen Code ein"}
								/>
							</div>
							<a
								className={"btn-small normal-case mt-0 lg:col-start-2 lg:col-span-3 " + (showCodes >= 5 ? " hidden" : " block")}
								onClick={() => (showCodes < 5 ? setShowCodes(showCodes + 1) : "")}
							>
								Weiteren Code eingeben
							</a>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default CodeValidation
