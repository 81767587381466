import { useAutoAnimate } from "@formkit/auto-animate/react"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	hasError?: boolean
	errorMsgPadding?: string
}

const ElemSelectProduct: React.FC<InputProps> = ({ className, props, label, errMsg, hasError, children, errorMsgPadding }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block select-item-product -mt-4 font-MomentsText" + (className || "")}>
			{label}
			<select
				className={
					"text-left w-full rounded-[5px] px-4 py-4 lg:py-0 mt-2 lg:text-base border-[1px] font-MomentsText leading-[22px] lg:leading-[47px] placeholder-black outline-0 mb-4 appearance-none bg-yellow " +
					(hasError ? "  js-formerror border-red" : " border-bordergray") +
					(props.name !== "salutation" ? " text-[13px] lg:text-base" : " text-base")
				}
				{...props}
			>
				{children}
			</select>

			{hasError && (
				<div ref={animationRef}>
					<div className={errorMsgPadding ? "formErrorMsg text-left font-MomentsText " + errorMsgPadding : " font-MomentsText  formErrorMsg pb-2"}>{errMsg}</div>
				</div>
			)}
		</label>
	)
}

export default ElemSelectProduct
