import React, { useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import Hero from "../components/home/Hero"
import Header from "../components/Header"

const Imprint: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-white">
			<Hero page="terms" />
			<>
				<div className="container max-w-[400px] mx-auto px-4 text-center">
					<h2 className="h2 mb-4 lg:mb-16 text-center mt-4 lg:mt-16 lg:leading-[52px] text-[36px] leading-[42px] font-MomentsText">
						<div className="text-darkgreen  lg:mb-16 font-bold ">Impressum</div>
					</h2>
					<p className="text-darkgreen mb-4 ">Dr. August Oetker Nahrungsmittel KG</p>
					<p className="text-darkgreen mb-4">Lutterstraße 14</p>
					<p className="text-darkgreen mb-4">33617 Bielefeld</p>
					<p className="text-darkgreen mb-4"></p>
					<p className="text-darkgreen mb-4">Telefon: +49 (0) 521 155-0</p>
					<p className="text-darkgreen mb-4">
						E-Mail: <a href="mailto:service@oetker.de">service@oetker.de</a>{" "}
					</p>
					<br />
					<br />
					<p className="text-darkgreen mb-4">
						Handelsregister: Amtsgericht Bielefeld HR A 14203
						<br />
						Umsatzsteuer-ID-Nr.: DE 814411623
					</p>
					<br /> <br />
					<p className="text-darkgreen mb-4">Vertretungsberechtigter im Sinne des § 5 TMG (Telemediengesetz): Daniela Emonts-Gast</p>
					<br /> <br />
					<p className="text-darkgreen mb-4">
						Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Die Plattform finden Sie unter{" "}
						<a href="http://ec.europa.eu/consumers/odr/" target="_blank" className="underline">
							Online Dispute Resolution | European Commission
						</a>
					</p>
					<br />
					<p className="text-darkgreen mb-4">
						Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
					</p>
				</div>
			</>
			<div className="text-center pt-5 py-10">
				<Link to="/" className="btn btn-invert mt-8">
					Zurück
				</Link>
			</div>
		</div>
	)
}
export default Imprint
