import { useContext } from "react"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"
import ElemSelect from "../elements/Select"

interface PersonProps {
	formElementChanged: Function
	validation: Object
}
const Person: React.FC<PersonProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)

	return (
		<section className="relative max-w-[1000px] mx-auto">
			<h3 className="text-darkgreen text-[32px]  mb-6 mt-24 font-MomentsBold">Angaben zu deiner Person</h3>
			<div className="grid gap-2 md:gap-5 grid-cols-1 md:grid-cols-[1fr_1fr] items-start mb-2 sm:mb-12 ">
				<ElemSelect
					label={"Anrede"}
					props={{
						type: "text",
						name: "salutation",
						value: globalState.salutation,
						minLength: 1,
						placeholder: "Anrede",
						onChange: e => formElementChanged(e, "salutation"),
					}}
					hasError={validation["invalid_salutation"] ? true : false}
					className="col-span-2 md:col-span-1 text-left"
					errMsg={validation["invalid_salutation_errMsg"] || "Bitte trage deine Anrede ein."}
				>
					<>
						<option className="text-lightgray" value="">
							Anrede
						</option>
						<option value="1">Herr</option>
						<option value="2">Frau</option>
						<option value="3">keine Angabe</option>
					</>
				</ElemSelect>
				<ElemInput
					label={"Vorname*"}
					props={{
						type: "text",
						name: "firstname",
						value: globalState.firstname,
						required: "required",
						minLength: 1,
						placeholder: "Vorname*",
						onChange: e => formElementChanged(e, "firstname"),
					}}
					hasError={validation["invalid_firstname"] ? true : false}
					className=""
					errMsg={validation["invalid_firstname_errMsg"] || "Bitte gib deinen Vornamen an."}
				/>
				<ElemInput
					label={"Nachname*"}
					props={{
						type: "text",
						name: "surname",
						value: globalState.surname,
						required: "required",
						minLength: 1,
						placeholder: "Nachname*",
						onChange: e => formElementChanged(e, "surname"),
					}}
					hasError={validation["invalid_surname"] ? true : false}
					className="col-span-2 md:col-span-1"
					errMsg={validation["invalid_surname_errMsg"] || "Bitte gib deinen Nachnamen an."}
				/>
				<ElemInput
					className="col-span-2 md:col-span-1"
					label={"E-Mail-Adresse*"}
					props={{
						type: "email",
						name: "email",
						value: globalState.email,
						required: "required",
						minLength: 1,
						placeholder: "E-Mail-Adresse*",
						onChange: e => formElementChanged(e, "email"),
					}}
					hasError={validation["invalid_email"] ? true : false}
					errMsg={validation["invalid_email_errMsg"] || "Bitte gib deine E-Mail-Adresse ein."}
				/>
			</div>
		</section>
	)
}

export default Person
