import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import Hero from "../components/home/Hero"
import Header from "../components/Header"

const Contact: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-white">
			<Hero />
			<div>
				<div className="container max-w-[400px] mx-auto px-4 text-center">
					<h2 className="h2 lg:pt-12 text-darkgreen mb-4">KONTAKT</h2>

					<p className="text-darkgreen mb-4 font-MomentsText ">
						<br />
						Du hast Fragen zur Aktion?
						<br />
						Dann nimm gerne Kontakt mit uns auf.
						<br />
						Wir freuen uns über Deine Nachricht!
						<br />
					</p>
					<p className="text-darkgreen mb-4 font-MomentsText ">
						<svg className="w-5 inline-block mr-2" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PhoneIcon">
							<path
								fill="#2E665E"
								d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"
							/>
						</svg>
						<a href="tel:+4952133721717">0521/33 72 17 17</a>
					</p>
					<p className="text-darkgreen mb-4 font-MomentsText ">(montags bis freitags von 8:30 - 17:30 Uhr)</p>
					<p className="text-darkgreen mb-4 font-MomentsText ">
						<svg className="w-5 inline-block mr-2" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="EmailIcon">
							<path fill="#2E665E" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z" />
						</svg>
						<a href="mailto:intermezzo@oetker-promotions.de">intermezzo@oetker-promotions.de</a>
					</p>
					<p className="text-darkgreen mb-4 font-MomentsText ">
						Allgemeine Informationen zur Aktion findest Du außerdem in den{" "}
						<Link
							to="/faq"
							target={"_blank"}
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "contact", link: "faq" })
								} catch (e) {}
							}}
						>
							<span className="underline">FAQ</span>
						</Link>
						.
					</p>
				</div>
			</div>
			<div className="text-center pt-5 py-10">
				<Link to="/" className="btn btn-invert mt-8">
					Zurück
				</Link>
			</div>
		</div>
	)
}
export default Contact
