import React, { createContext, useReducer } from "react"
import Reducer from "./reducer"

export const initialState = {
	salutation: "",
	firstname: "",
	surname: "",
	email: "",
	iban: "",
	bic: "",
	question1: "",
	question2: "",
	question3: "",
	code1: "",
	code2: "",
	code3: "",
	code4: "",
	code5: "",
	mafoOptIn: false,
	privacyTermsOptIn: false,
	over18OptIn: true,
}

const Store = ({ children }) => {
	const [globalState, dispatch] = useReducer(Reducer, initialState)
	return <Context.Provider value={{ globalState, dispatch }}>{children}</Context.Provider>
}
export const Context = createContext<{
	globalState
	dispatch: React.Dispatch<any>
}>({
	globalState: initialState,
	dispatch: () => {},
})
export default Store
