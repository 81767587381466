export enum ERRORTYPES {
	FORMVALIDATION = "Bitte fülle alle Felder korrekt aus.",
	RECEIPT_UPLOAD_ERROR = "Leider stimmt etwas mit Deinem Kassenbon nicht. Wir können keinen Kauf eines teilnehmenden Produktes erkennen. ",
	FILESIZE = " Bitte überprüfe ob der Kassenbon alle notwendigen Voraussetzungen erfüllt. Bei weiteren Problemen melde dich gern unter info@violife-rabatt.de.",
	DOUBBLED = "Du hast Deinen Kassenbon bereits verwendet.",
	MAXTRIES = "Du hast heute bereits zu häufig an unserem Gewinnspiel teilgenommen. Bitte probiere es morgen noch einmal.",
	SERVER = "Bei der Übertragung deiner Daten ist ein Fehler aufgetreten. Bitte versuche es erneut. Sollten die Probleme weiterhin bestehen, kannst du dich an unser Service-Team wenden. Du erreichst uns per E-Mail unter intermezzo@oekter-promotions.de. Bitte entschuldige die Unannehmlichkeiten.",
	UPLOADSMISSING = "Bitte lade einen Kassenbon und ein Produktfoto hoch. ",
	UPLOADSMISSINGRECEIPT = "Bitte lade deinen Kassenbon hoch.",
	UPLOADSMISSINGPRODUCT = "Bitte lade dein Produktfoto hoch.",
}

export const PROMOTIMER = parseInt(Math.floor(Date.now() / 1000).toString()) < 1677625200

export const WINNINGTEXT = {
	1: "Du hast 1€ Rabatt gewonnen.",
	2: "Du hast 2€ Rabatt gewonnen.",
	3: "Du bekommst den vollständigen Kaufpreis erstattet!",
}

export const MAX_PRODUCT_PRICE = 3.0
export const MINCODELETTERS = 10

export enum QUESTIONTRANSLATION {
	a = "Radeberger",
	b = "Schöfferhofer",
	c = "Jever",
	d = "Oberdorfer Helles",
	e = "Allgäuer Büble Bier",
	f = "Binding",
	g = "Brinkhoff's",
	h = "Berliner Kindl",
	i = "Stuttgarter Hofbräu",
	j = "Ur-Krostitzer",
	k = "Berliner Pilsener",
}
