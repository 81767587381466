import { useEffect } from "react"
import Hero from "../components/home/Hero"
import Sogehts from "../components/home/Sogehts"

function Promoover() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="">
			<Hero page="pre" />
			<Sogehts />
		</div>
	)
}
export default Promoover
