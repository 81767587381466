import React, { useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import Hero from "../components/home/Hero"
import Header from "../components/Header"

const Terms: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="">
			<Hero page="terms" />
			<>
				<div className="container max-w-[800px] mx-auto px-4  ">
					<h2 className="h2 mb-4 lg:mb-16 text-center mt-4 lg:mt-16 lg:leading-[52px] text-[36px] leading-[42px]">
						<div className="text-darkgreen  lg:mb-16 font-Atma font-bold ">Teilnahmebedingungen</div>
					</h2>
					<p className="text-darkgreen">
						Die Dr. August Oetker Nahrungsmittel KG, Lutterstraße 14, 33617 Bielefeld (im Folgenden „Dr. Oetker“) führt mit den Produkten des Sortimentes Dr. Oetker Intermezzo im
						Zeitraum vom 01.04.2024 bis zum 11.08.2024 eine Cashback-Aktion mit Staffelrabatt durch. Mit der Teilnahme an der Aktion akzeptierst du die folgenden
						Teilnahmebedingungen.
					</p>
					<h3 className="h3 text-darkgreen py-4 mt-6 ">1. Teilnahme</h3>
					<p className=" text-darkgreen py-4">a. Teilnahmeberechtigte</p>
					<p className="mb-4 text-darkgreen">
						An der Aktion teilnahmeberechtigt ist jede natürliche Person, deren Wohnsitz in Deutschland und Bankkonto sich im SEPA-Raum befinden und die zum Zeitpunkt der Teilnahme
						an der Aktion das achtzehnte (18.) Lebensjahr vollendet hat und unbeschränkt geschäftsfähig ist (nachfolgend der „Teilnehmer“). Der Teilnehmer muss gleichzeitig
						Kontoinhaber sein.
					</p>
					<p className="mb-4 text-darkgreen">
						Jeder Teilnehmer kann nur im eigenen Namen und pro IBAN nur einmal an der Cashback-Aktion teilnehmen. Teilnahmeberechtigt sind nur Personen, die die Aktionspackung(en)
						selbst gekauft haben oder die mit dem Käufer in einem Haushalt leben.
					</p>
					<p className=" text-darkgreen py-4">b. Teilnehmende Produkte:</p>
					<p className="mb-4 text-darkgreen">
						Die Aktion gilt nur für die folgenden Produkte der Range „Dr. Oetker Intermezzo“ (nachfolgend das „Produkt“):
						<ul>
							<li className="mb-4 mt-4">
								<ul>
									<li>Intermezzo Champignon Steinpilz-Ricotta </li>
									<li>Intermezzo Tomate Mozzarella</li>
									<li>Intermezzo Salami Calabrese </li>
									<li>Intermezzo Thunfisch MSC mit Zwiebeln</li>
									<li>Intermezzo Schinken mit Sauerrahm</li>
									<li>Intermezzo Hot Chili mit Pepperoni-Salami</li>
								</ul>
							</li>
						</ul>
					</p>
					<p className=" text-darkgreen py-4">c. Ablauf</p>
					<p className="mb-4 text-darkgreen">
						Für die Teilnahme benötigt der Teilnehmer den bzw. die Promotioncode(s) von der bzw. den Innenseite(n) der Aktionspackung(en). Jede Aktionspackung erhält einen
						Promotioncode. Die Aktionspackungen lassen sich durch einen Störer auf der Verpackungsvorderseite identifizieren. Die Höhe der Cashback-Beträge ist abhängig von der
						Anzahl der gekauften Aktionspackungen. Die Cashback-Staffelung lautet wie folgt: 1x kaufen = 0,60 €; 2x kaufen = 1,40 €; 3x kaufen = 2,40 €; 4x kaufen = 3,60 €; 5x
						kaufen = 5,00 €.
					</p>
					<p className="mb-4 text-darkgreen">
						Eine Erstattung entsprechend der Cashback-Staffelung erfolgt nur, sofern im Aktionszeitraum, zwischen dem 01.04.2024 und 11.08.2024, der Upload des bzw. der
						Promotioncode(s) von der bzw. den Innenseite(n) der Aktionspackung(en) und das korrekte Ausfüllen des Registrierungsformulars auf der Aktionswebseite{" "}
						<a href="https://www.intermezzo-cashback.de" className="underline" target="_blank">
							www.intermezzo-cashback.de
						</a>{" "}
						erfolgt sind. Pro IBAN darf nur einmal an der Cashback-Aktion teilgenommen werden, wobei maximal 5 Promotioncodes hochgeladen werden können. Jeder Promotioncode kann
						nur einmal genutzt werden. Nicht mit anderen Rabattaktionen kombinierbar.
					</p>
					<p className="mb-4 text-darkgreen">
						Die Teilnahme an der Aktion erfordert einen Internetzugang und ist ausschließlich über die Internetseite www.intermezzo-cashback.de möglich. Der genannte
						Cashback-Betrag entsprechend der Staffelung wird in Euro auf das vom Teilnehmer angegebene Konto (SEPA-Raum) überwiesen. Die Rückerstattung erfolgt nach erfolgreicher
						Teilnahme binnen 30 Tagen auf das angegebene Bankkonto. Eventuelle Gebühren, die die kontoführende Bank des Teilnehmers für den Empfang des Geldes oder die Umrechnung
						in andere Währungen berechnet, hat der Kontoinhaber selbst zu tragen. Der Teilnehmer muss ebenso die Kosten tragen, die mit der Teilnahme über das Internet verbunden
						sind (Nutzungsentgelt).
					</p>
					<h3 className="h3 text-darkgreen py-4">2.Sonstiges</h3>
					<p className="mb-4 text-darkgreen">
						a) Weiterführende Informationen zur Verarbeitung personenbezogener Daten enthält der Datenschutzhinweis unter{" "}
						<a href="https://www.oetker.de/datenschutzhinweis" target="_blank" className="underline">
							Datenschutzhinweis | Dr. Oetker.
						</a>
					</p>
					<p className="mb-4 text-darkgreen">
						b) Dr. Oetker behält sich das Recht der jederzeitigen Unterbrechung oder Beendigung der Aktion vor, wenn die weitere Durchführung der Aktion erheblich beeinträchtigt
						ist, ohne dass dies von Dr. Oetker zu vertreten ist. Änderungen im Ablauf der Aktion bleiben vorbehalten, soweit für diese Änderungen ein sachlicher Grund besteht, der
						bei Start der Aktion nicht voraussehbar war, und sie für die Teilnehmer zumutbar sind.
					</p>
					<p className="mb-4 text-darkgreen">
						c) Dr. Oetker behält sich das Recht vor, Teilnehmer aufgrund von falschen Angaben, Verstoß gegen die Teilnahmebedingungen, Gesetzesvorschriften oder Rechte Dritter,
						Manipulationen oder der Verwendung unerlaubter Hilfsmittel (wie z.B. Bots, automatisierte Skripte etc.) von der Aktion auszuschließen. <br />
						<br />
						Der Teilnehmer haftet für alle Schäden, die er durch schuldhafte Erfüllung der oben aufgeführten Ausschlussgründe (d.h. Gründe zum Ausschluss von Teilnehmern vom
						Gewinnspiel) verursacht.
					</p>
					<p className="mb-4 text-darkgreen">
						d) Dr. Oetker ist für die Verfügbarkeit der Aktionsprodukte im Handel während des Aktionszeitraums nicht verantwortlich. Sollte das vom Teilnehmer gewünschte
						Aktionsprodukt im Aktionszeitraum nicht verfügbar sein, entstehen dadurch keine Ansprüche gegen Dr. Oetker. <br /> <br />
						Dr. Oetker steht nicht für die jederzeitige Verfügbarkeit der Aktionswebsite ein; die Teilnehmer sind aufgerufen, die Eingabe zu einem späteren Zeitpunkt zu
						wiederholen. Sollte die Website bei Eingabe nicht verfügbar sein, entstehen dadurch keine Ansprüche gegen den Veranstalter, ausgenommen im Falle von Vorsatz oder grober
						Fahrlässigkeit.
					</p>
					<p className="mb-4 text-darkgreen">e) Es gilt das Recht der Bundesrepublik Deutschland.</p>
					<p className="mb-4 text-darkgreen">
						f) Sollten einzelne dieser Bedingungen unwirksam sein oder werden, bleibt die Gültigkeit der übrigen Teilnahmebedingungen hiervon unberührt.
					</p>
					<p className="mb-4 text-darkgreen">
						g) Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Verbraucher für die außergerichtliche Beilegung einer Streitigkeit aus
						Online-Verträgen mit einem in der EU niedergelassenen Unternehmen nutzen können. Diese finden Sie unter{" "}
						<a href="https://ec.europa.eu/consumers/odr/" target="_blank" className="underline">
							https://ec.europa.eu/consumers/odr/
						</a>
						. <br /> <br />
						Dr. Oetker ist weder verpflichtet noch bereit, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
					</p>
				</div>
			</>
			<div className="text-center pt-5 py-10">
				<Link to="/" className="btn btn-invert mt-8">
					Zurück
				</Link>
			</div>
		</div>
	)
}
export default Terms
