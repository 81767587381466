import { useState } from "react"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import { Link } from "react-router-dom"
import MobileMenu from "./MobileMenu"
import { PROMOTIMER } from "../config"

const Header = ({ isPromoOver = false, addClass = "" }) => {
	const [showMenu, setShowMenu] = useState(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	const isPromoStarts = parseInt(Math.floor(Date.now() / 1000).toString()) > 1723413600
	function scrollToAnchor(anchor) {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-" + anchor).getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY,
					left: 0,
					behavior: "smooth",
				})

				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "header", link: anchor })
				} catch (e) {}
			} catch (error) {}
		}, 300)
	}

	return (
		<>
			<div ref={animationRef}>{showMenu && <MobileMenu setShowMenu={setShowMenu} isPromoOver={isPromoOver} scrollToAnchor={scrollToAnchor} />}</div>

			<header className=" top-0 z-[200]  pt-8 pb-8 relative max-w-[1600px] mx-auto">
				<div className="grid col-auto grid-cols-[20%_1fr] items-end px-6 lg:px-12">
					<a href="https://www.oetker.de/">
						<img src={process.env.PUBLIC_URL + "/images/logo-droetker.png"} alt="DrOetker" className="max-w-[120px]" />
					</a>
					<ul className="hidden lg:grid grid-flow-col self-center justify-self-end gap-14 w-max flex-wrap  text-black">
						<li>
							<Link
								to="/"
								className="header-item"
								onClick={() => {
									scrollToAnchor("sogehts")
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "header", link: "sogehts" })
									} catch (e) {}
								}}
							>
								So geht's
							</Link>
						</li>
						{!isPromoStarts && (
							<>
								<li>
									<Link
										to="/"
										className="header-item"
										onClick={() => {
											scrollToAnchor("mitmachen")
											try {
												// eslint-disable-next-line
												let dataLayer = window["dataLayer"] || []
												dataLayer.push({ event: "header", link: "jetzt mitmachen" })
											} catch (e) {}
										}}
									>
										Jetzt Code einlösen
									</Link>
								</li>
							</>
						)}
						<li>
							<Link
								to={process.env.PUBLIC_URL + "/teilnahmebedingungen"}
								className="header-item"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "header", link: "teilnahmebedingungen" })
									} catch (e) {}
								}}
							>
								Teilnahmebedingungen
							</Link>
						</li>
						<li>
							<Link
								to={process.env.PUBLIC_URL + "/faq"}
								className="header-item"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "header", link: "faq" })
									} catch (e) {}
								}}
							>
								FAQ
							</Link>
						</li>
						<li>
							<Link
								to={process.env.PUBLIC_URL + "/kontakt"}
								className="header-item"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "header", link: "kontakt" })
									} catch (e) {}
								}}
							>
								Kontakt
							</Link>
						</li>
					</ul>
					<div className=" lg:hidden self-start justify-self-end" onClick={() => setShowMenu(true)}>
						<img src={process.env.PUBLIC_URL + "/images/navbtn.svg"} alt="DrOetker" className="max-w-[120px]" />
					</div>
				</div>
			</header>
		</>
	)
}

export default Header
