import React, { useEffect } from "react"
import Hero from "../components/home/Hero"
import Sogehts from "../components/home/Sogehts"
import Form from "../components/Forms/Form"
import { useNavigate } from "react-router-dom"

const Home: React.FC = () => {
	const navigate = useNavigate()
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<Hero page="home" />
			<Sogehts />
			<Form />
		</>
	)
}
export default Home
